import React, { useState } from "react"
import styled from "@emotion/styled"
import { createHTML } from "../utils"

import Loading from "./Loading"

const Container = styled.section`
  max-width: 130rem;
  margin: 0 auto;
  padding: 3rem 1.5rem;
`

const Primary = styled.h2`
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
  color: #8cc949;
  background: linear-gradient(to right, #8cc949, #78b526);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
`

const Subtitle = styled.h3`
  font-size: 1.9rem;
  font-weight: 400;
  text-align: center;
  padding: 1.5rem 10rem;
  @media only screen and (max-width: 52em) {
    padding: 1.5rem 0;
  }
`

const Wrapper = styled.div`
  padding: 3rem 0;
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 4rem;
  border-top: 1px solid #505050;
  align-items: center;
  z-index: 10;
  :last-child {
    border-bottom: 1px solid #505050;
  }
`

const Header = styled.label`
  font-size: 2.5rem;
  color: #505050;
  padding: 3rem 0;
  grid-row: 1 / 2;
  cursor: pointer;
  z-index: 10;
`

const Checkbox = styled.input`
  display: none;
`

const ExpandBtn = styled.label`
  height: 3rem;
  width: 3rem;
  position: relative;
  z-index: 10;
  transition: all 0.3s;
  ${Checkbox}:checked + & {
    transform: rotate(135deg);
  }
  span {
    display: block;
    cursor: pointer;
    height: 100%;
    width: 100%;
    position: relative;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #505050;
    }
    &::before {
      height: 3px;
      width: 50%;
    }
    &:after {
      height: 50%;
      width: 3px;
    }
  }
`

const Body = styled.span`
  padding-bottom: 3rem;
  grid-row: 2 / 3;
  grid-column: 1 / -1;
  display: none;
  z-index: 10;
  a {
    color: #8cc949;
    text-decoration: none;
  }
  ol {
    list-style-position: inside;
  }
  ${Checkbox}:checked ~ & {
    display: block;
  }
  @media only screen and (max-width: 37.5em) {
    padding-bottom: 1.5rem;
  }
`

const TextField = styled.input`
  font-size: 1.5rem;
  width: 100%;
  padding: 16px 25px;
  margin: 8px 0;
  display: inline-block;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  box-sizing: border-box;
  border: none;
`

const Questions = ({ data: { title, subtitle, data } }) => {
  const [searchTerm, setSearchTerm] = useState("")

  const handleSearch = (e) => {
    setSearchTerm(e.target.value)
  }
  return (
    <Container>
      <Primary>{title}</Primary>
      <Subtitle>{subtitle}</Subtitle>
      <TextField
        name="searchTerm"
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        placeholder="Search..."
      />
      <Wrapper>
        {!data ? (
          <Loading size="5" />
        ) : (
          getFilteredFAQs(data, searchTerm).map((item, count) => (
            <Content key={count}>
              <Header htmlFor={`expandBtn-${count}`}>{item.title}</Header>
              <Checkbox type="checkbox" id={`expandBtn-${count}`} />
              <ExpandBtn htmlFor={`expandBtn-${count}`}>
                <span />
              </ExpandBtn>
              <Body dangerouslySetInnerHTML={createHTML(item.body)} />
            </Content>
          ))
        )}
      </Wrapper>
    </Container>
  )
}

export default Questions

const getFilteredFAQs = (faqs, searchTerm) => {
  if (searchTerm === "") {
    return faqs
  }
  const formattedTerm = searchTerm.trim().toLowerCase()

  const filteredFAQs = (faqs || []).filter((faq) => {
    const { title, body } = faq
    if (
      title.toLowerCase().includes(formattedTerm) ||
      body.toLowerCase().includes(formattedTerm)
    ) {
      return true
    } else {
      return false
    }
  })
  return filteredFAQs
}
